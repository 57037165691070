<template>
  <div class="container">
    <div class="title">产品信息</div>
    <div class="line"></div>
    <div class="imgWrap" v-loading="loading">
      <div>
        <el-carousel
          :initial-index="value1"
          :arrow="list.length > 1 ? 'always' : 'never'"
          dots="none"
          height="190px"
          :autoplay="false"
          trigger="click"
          :loop="true"
        >
          <el-carousel-item
            v-for="(item, index) in list"
            :key="index"
            :name="index + ''"
          >
            <div class="demo-carousel clearfix">
              <div
                class="fl twoImgWrap"
                v-for="(ele, index) in item"
                :key="index"
              >
                <el-image
                  :src="ele.cover_url"
                  fit="scale-down"
                  style="cursor: pointer"
                  @click="visitPreview(ele)"
                >
                  <div slot="placeholder" class="image-slot">
                    <div class="loading">加载中...</div>
                  </div>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div class="textWrap textOverflow">
                  {{ele.name|textFormat}}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-if="list.length === 0">
        <noDataImg></noDataImg>
      </div>
    </div>
    <el-dialog
      title="信息介绍"
      :visible.sync="dialogVisible"
      width="1238px"
    >
      <div class="testTitle">{{richTextInfo.name|textFormat}}</div>
      <div v-if="dialogVisible" v-html="richTextInfo.content"></div>
    </el-dialog>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>

<script>
import { splitArr } from "~/baseUtils";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  name: "companyProduct",
  components: {
    videoPlay,
  },
  props: {
    company_id: {
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      loading: false,
      value1: 0,
      dialogVisible:false,
      richTextInfo:''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      let params = {};
      params.nopage = 1;
      params.company_id = this.company_id;
      params.is_show=1
      let res = await this.$store.dispatch(
        "API_company/getCompanyProductList",
        params
      );
      if (res.success) {
        this.list = splitArr(res.data, 3);
        this.loading = false;
      }
    },
    visitPreview(item) {
      if (item.ptype === 1) {
        //图文
        if(item.content){
          this.richTextInfo=item
          this.dialogVisible=true
        }
      } else {
        //视频
        if (item.video_url) {
          this.$refs.vidoePlay.startPlay(item.video_url);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  margin-top: 20px;
  padding: 24px 24px 25px;
  background: #ffffff;
  width: 100%;
  /deep/ .el-carousel__indicator--horizontal {
    display: none;
    // .el-carousel__button {
    //   width: 6px;
    //   height: 6px;
    //   background: #d1d1d1;
    //   border-radius: 50%;
    // }
    // &.is-active {
    //   .el-carousel__button {
    //     background: #ff5155;
    //   }
    // }
  }
  // /deep/ .el-carousel__arrow {
  //   width: 20px;
  //   height: 20px;
  //   bottom: -36px;
  //   background: #fff;
  // }
  // /deep/ .el-carousel__arrow--left {
  //   left: 0px;
  //   top: unset;
  //   .el-icon-arrow-left {
  //     font-weight: 600;
  //     color: #ff5155;
  //   }
  // }
  // /deep/ .el-carousel__arrow--right {
  //   right: 0px;
  //   top: unset;
  //   .el-icon-arrow-right {
  //     font-weight: 600;
  //     color: #ff5155;
  //   }
  // }
  .demo-carousel {
    .fl {
      position: relative;
    }
    .fl:last-child {
      margin-right: 0px !important;
    }
    .el-image {
      width: 240px;
      height: 167px;
      background: rgb(242, 242, 242);
    }
    img {
      width: 240px;
      height: 167px;
    }
    .image-slot {
      text-align: center;
      line-height: 100%;
      width: 100%;
      height: 100%;
      background: rgb(242, 242, 242);
      color: #999;
      font-size: 30px;
      position: relative;
      .el-icon-picture-outline,
      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .loading {
        font-size: 12px;
      }
    }
  }
  .fl {
    position: relative;
  }
  .title {
    color: #333333;
    font-size: 16px;
    font-weight: 800;
  }
  .line {
    height: 1px;
    background: #dcdfe6;
    width: 100%;
    margin-top: 20px;
  }
  .imgWrap {
    margin-top: 16px;
    position: relative;
  }
}
.twoImgWrap {
  width: 240px;
  height: 190px;
  margin-right: 64px;
  &:nth-child(3) {
    margin-right: 0;
  }
}
.testTitle{
  text-align: center;
  font-size: 26px;
  margin-bottom: 10px;
}
</style>
