import { render, staticRenderFns } from "./companyVideo.vue?vue&type=template&id=7fbc4d9e&scoped=true"
import script from "./companyVideo.vue?vue&type=script&lang=js"
export * from "./companyVideo.vue?vue&type=script&lang=js"
import style0 from "./companyVideo.vue?vue&type=style&index=0&id=7fbc4d9e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fbc4d9e",
  null
  
)

export default component.exports